import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Img from "gatsby-image";

const ServicesPage = () => {

    const data = useStaticQuery(graphql`
    query {
      services_travel: file(relativePath: { eq: "services-travel.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 525, maxHeight: 360) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      services_entertainment: file(relativePath: { eq: "services-entertainment.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 525, maxHeight: 360) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      services_experiences: file(relativePath: { eq: "services-experiences.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 525, maxHeight: 360) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      services_dining: file(relativePath: { eq: "services-dining.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 525, maxHeight: 360) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `);

    return (
        <Layout>
            <SEO title="Services" />
            <div className="container">

                <div className="opening">
                    <h1>Services</h1>
                    <p>
                        With partnerships across the country and around the world, Lux Delux is your key to white-glove concierge services and lifestyle management. From luxury travel and high-end dining to exclusive, one-of-a-kind bespoke experiences, our mission is to provide our clients with the very best life has to offer.
                    </p>
                </div>

                <div className="row">
                <div className="half-block">

                    <Img objectFit="cover" fluid={data.services_travel.childImageSharp.fluid} />
                    <h3>Travel</h3>
                    <p>
                        Thanks to our exclusive partnerships with the world’s top hospitality companies, you won’t have to think twice when booking your next getaway. Whether it’s a quick weekend retreat, a last-minute business trip, or an all-inclusive escape to a remote locale, your Lux Delux concierge can arrange everything from airfare in a private jet to luxe penthouse suites with panoramic views, leaving you free to make the most of your trip.
                    </p>

                </div>
                <div className="half-block">

                    <Img objectFit="cover"  fluid={data.services_entertainment.childImageSharp.fluid} />
                    <h3>Entertainment</h3>
                    <p>
                        From live sporting events and stage productions to backstage passes at the hippest music festivals and top-40 concerts, we’ve got your all-access pass to the biggest names in live entertainment. Whether it’s the latest from Cirque du Soleil, the final game of the World Series, or the hottest new thing on Broadway, Lux Delux handles every aspect of the ticketing process so you can focus on what matters most—enjoying the best entertainment on planet earth.
                    </p>
                </div>
                </div>
                <div className="row">
                <div className="half-block">

                    <Img objectFit="cover"  fluid={data.services_experiences.childImageSharp.fluid} />
                    <h3>Experiences</h3>
                    <p>
                        Looking for last-minute seats at a sold-out Hamilton performance? Exclusive access to the buzziest new club? No matter what you’re dreaming of, Lux Delux can make it happen. Our bespoke member experiences offer the chance to make any dream come true—no matter how big. Arrive by private helicopter at the hottest music festivals? Been there. Score impossible-to-find VIP seating at the Super Bowl? Done that. From bookings and nightlife to entertainment and beyond, Lux Delux makes it happen.
                    </p>

                </div>
                <div className="half-block last">

                    <Img objectFit="cover"  fluid={data.services_dining.childImageSharp.fluid} />
                    <h3>Dining</h3>
                    <p>
                        From chef’s-table seating at the trendiest restaurants to expertly curated street food tours of Bangkok, Seoul, and New Orleans, Lux Delux is your go-to for the best of the best in dining. Whether you’re in the mood for a perfectly prepared steak, authentic Trinidadian doubles, or final word in wood-fired pizza, let Lux Delux handle the details, so all you’ve got to do is pull up a chair.
                    </p>
                </div>
                </div>

            </div>
        </Layout>
    )
};

export default ServicesPage
